import React, { useState, useEffect } from 'react';

import logoCiad from "../assets/images/cnadLoWhite.png";

function Navbar() {
  const [open, setOpen] = useState(false);
  const [navbarBg, setNavbarBg] = useState('bg-transparent');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && !open) {
        setNavbarBg('bg-[#1A2E6E]');
      } else if (!open) {
        setNavbarBg('bg-transparent');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  const toggleMenu = () => {
    if (!open) {
      setNavbarBg('bg-[#1A2E6E] pt-8'); // Cambia a color sólido cuando se abre el menú
    } else if (window.scrollY <= 50) {
      setNavbarBg('bg-transparent'); // Vuelve a transparente cuando se cierra el menú
    }
    setOpen(!open); // Cambia el estado del menú
  };

  return (
    <div className={`w-full ${navbarBg} fixed top-0 left-0 z-20 transition-colors duration-300`}>
      <div className="container mx-auto w-full">
        <div className="w-full text-white">
          <div className="flex flex-col w-full px-4 py-4 mx-auto lg:items-center lg:justify-between lg:flex-row lg:px-6">
            <div className="flex flex-row items-center justify-between w-full">
              <a href="#" className="font-semibold tracking-widest text-gray-900 uppercase rounded-lg">
                <img src={logoCiad} className="w-40" alt="Logo" />
              </a>
              <button className="rounded-lg lg:hidden focus:outline-none focus:shadow-outline" onClick={toggleMenu}>
                <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                  {open ? (
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  ) : (
                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                  )}
                </svg>
              </button>
            </div>

            <nav className={`w-3/5 flex-col flex-grow pb-4 lg:pb-0 lg:flex lg:justify-end lg:flex-row ${open ? 'flex' : 'hidden'}`}>
              <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#INICIO">Inicio</a>
              <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#Nosotros">Nosotros</a>
              <a className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg lg:mt-0 lg:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline" href="#Educacion">Educacion</a>
              <a className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" href="https://portal.ciad.sv/login/index.php">Ir a portal</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
