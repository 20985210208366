import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import "../styles.css";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

gsap.registerPlugin(ScrollTrigger);

export default function ProcessToDevelop() {
  useEffect(() => {
    // Obtén todas las secciones con la clase "panel"
    const sections = gsap.utils.toArray(".panel");

    // Animación de desplazamiento
    const scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: "none",
      scrollTrigger: {
        trigger: ".container-scroll-gsap",
        pin: true,
        scrub: 0.1,
        end: "+=3000"
      }
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  return (
    <>
      <div id="TUIDEA"></div>
      <div class="bg-gradient-to-r from-blue-incubator-navbar to-blue-600 body-font block lg:hidden">

        <div class="container px-5 py-24 mx-auto" >
          <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            transitionDuration={1}
            customTransition="transform 2800ms"
            showDots={false}
            arrows={true}
          >

            <div class="flex flex-wrap px-4">

              <div class="sm:w-1/2 mb-10 px-4 flex items-center">

                <div >
                <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 w-full sm:w-full">
                      Identificar la Idea de  <span class="text-yellow-incubaodr">Negocio</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Definirás claramente la propuesta de valor de tu idea, en esta fase lograrás identificar el problema que tu idea resolverá. Además, deberás de realizar un análisis de mercado para comprender la demanda y la competencia para determinar si tu idea realmente es factible desarrollarla.
                    </p>

                  <div class="flex md:justify-start  justify-center">
                    <a href="#CONTACTANOS"
                      class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                      Más información
                    </a>

                  </div>
                </div>


              </div>

              <div class="sm:w-1/2 mb-10 px-4">

                <div class="container">



                  <div class="flex flex-col">

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

            </div>

            <div class="flex flex-wrap px-4">

              <div class="sm:w-1/2 mb-10 px-4 flex items-center">

                <div>
                <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 w-full sm:w-full">
                    Desarrollar un Plan de <span class="text-yellow-incubaodr">Negocios</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Crearás un plan detallado que aborde aspectos como el modelo de negocio, estrategias de marketing, análisis financiero y proyecciones a corto y largo plazo.
                    </p>

                  <div class="flex md:justify-start  justify-center">
                    <a href="#CONTACTANOS"
                      class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                      Más información
                    </a>

                  </div>
                </div>


              </div>

              <div class="sm:w-1/2 mb-10 px-4">

                <div class="container">



                  <div class="flex flex-col">

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

            </div>

            <div class="flex flex-wrap px-4">

              <div class="sm:w-1/2 mb-10 px-4 flex items-center">

                <div>
                <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 w-full m:w-full">
                    Construir un <span class="text-yellow-incubaodr">Equipo Sólido</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Con ayuda del equipo de Ticongle Incubator construirás un equipo que cumpla con todas las habilidades necesarias para desarrollar tu idea y brindarte apoyo en todo el proceso.
                    </p>

                  <div class="flex md:justify-start  justify-center">
                    <a href="#CONTACTANOS"
                      class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                      Más información
                    </a>

                  </div>
                </div>


              </div>

              <div class="sm:w-1/2 mb-10 px-4">

                <div class="container">



                  <div class="flex flex-col">

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

            </div>

            <div class="flex flex-wrap px-4">

              <div class="sm:w-1/2 mb-10 px-4 flex items-center">

                <div>
                <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 w-full  sm:w-full">
                    Prototipo o Producto <span class="text-yellow-incubaodr">Mínimo Viable (MVP)</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Junto al equipo de expertos de Ticongle Incubator desarrollarás un prototipo o MVP para validar tu concepto y obtener retroalimentación de usuarios a quienes buscas dirigirte.
                    </p>

                  <div class="flex md:justify-start  justify-center">
                    <a href="#CONTACTANOS"
                      class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                      Más información
                    </a>

                  </div>
                </div>


              </div>

              <div class="sm:w-1/2 mb-10 px-4">

                <div class="container">



                  <div class="flex flex-col">

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

            </div>


            <div class="flex flex-wrap px-4">

              <div class="sm:w-1/2 mb-10 px-4 flex items-center">

                <div>
                <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 w-full sm:w-full">
                    Investigación de  <span class="text-yellow-incubaodr">Mercado y Validación</span>
                    </h2>

                    <p class="leading-relaxed text-justify text-white">
                    Realizarás una investigación de mercado detallada para confirmar la viabilidad de tu idea. En la cual buscarás obtener comentarios de clientes potenciales para ajustar y mejorar tu producto o servicio.
                    </p>

                  <div class="flex md:justify-start  justify-center">
                    <a href="#CONTACTANOS"
                      class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                      Más información
                    </a>

                  </div>
                </div>


              </div>

              <div class="sm:w-1/2 mb-10 px-4">

                <div class="container">



                  <div class="flex flex-col">

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

            </div>

            <div class="flex flex-wrap px-4">

              <div class="sm:w-1/2 mb-10 px-4 flex items-center">

                <div>
                <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 w-full sm:w-full">
                      Escalabilidad y <span class="text-yellow-incubaodr">Crecimiento</span>
                    </h2>

                    <p class="leading-relaxed text-justify text-white">
                    Desarrollarás estrategias para escalar tu negocio. Verás la capacidad financiera que tendrás para expandir tu equipo, mejorar la infraestructura y buscar nuevas oportunidades de mercado.
                    </p>

                  <div class="flex md:justify-start  justify-center">
                    <a href="#CONTACTANOS"
                      class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                      Más información
                    </a>

                  </div>
                </div>


              </div>

              <div class="sm:w-1/2 mb-10 px-4">

                <div class="container">



                  <div class="flex flex-col">

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>

                    <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                      <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                        <p class="text-white text-justify">
                          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                          temporibus dolore mollitia molestias.
                        </p>
                      </div>
                    </div>



                  </div>
                </div>

              </div>

            </div>

          </Carousel>
        </div>

      </div>

      <div className="hidden lg:block">
    
      <div class="container-scroll-gsap bg-gradient-to-r from-blue-incubator-navbar to-blue-600 body-font block">
          
          <div class="panel">
            <div class="container ">
              <div class="flex flex-wrap px-4">
                <div class="sm:w-1/2 px-4 flex items-center">

                  <div >
                    <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 md:w-[60%] sm:w-full">
                      Identificar la Idea de  <span class="text-yellow-incubaodr">Negocio</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Definirás claramente la propuesta de valor de tu idea, en esta fase lograrás identificar el problema que tu idea resolverá. Además, deberás de realizar un análisis de mercado para comprender la demanda y la competencia para determinar si tu idea realmente es factible desarrollarla.
                    </p>

                    <div class="flex md:justify-start  justify-center">
                      <a href="#CONTACTANOS"
                        class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                        Más información
                      </a>

                    </div>
                  </div>


                </div>

                <div class="sm:w-1/2 px-4">

                  <div class="container">



                    <div class="flex flex-col">

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <section class="panel ">
            <div class="container ">
              <div class="flex flex-wrap px-4">
                <div class="sm:w-1/2 px-4 flex items-center">

                  <div >
                    <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 md:w-[65%] sm:w-full">
                    Desarrollar un Plan de <span class="text-yellow-incubaodr">Negocios</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Crearás un plan detallado que aborde aspectos como el modelo de negocio, estrategias de marketing, análisis financiero y proyecciones a corto y largo plazo.
                    </p>

                    <div class="flex md:justify-start  justify-center">
                      <a href="#CONTACTANOS"
                        class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                        Más información
                      </a>

                    </div>
                  </div>


                </div>

                <div class="sm:w-1/2 px-4">

                  <div class="container">



                    <div class="flex flex-col">

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section class="panel ">
            <div class="container ">
              <div class="flex flex-wrap px-4">
                <div class="sm:w-1/2 px-4 flex items-center">

                  <div >
                    <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 md:w-[55%] sm:w-full">
                    Construir un <span class="text-yellow-incubaodr">Equipo Sólido</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Con ayuda del equipo de Ticongle Incubator construirás un equipo que cumpla con todas las habilidades necesarias para desarrollar tu idea y brindarte apoyo en todo el proceso.
                    </p>

                    <div class="flex md:justify-start  justify-center">
                      <a href="#CONTACTANOS"
                        class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                        Más información
                      </a>

                    </div>
                  </div>


                </div>

                <div class="sm:w-1/2 px-4">

                  <div class="container">



                    <div class="flex flex-col">

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section class="panel  ">
            <div class="container ">
              <div class="flex flex-wrap px-4">
                <div class="sm:w-1/2 px-4 flex items-center">

                  <div >
                    <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3  sm:w-full">
                    Prototipo o Producto <span class="text-yellow-incubaodr">Mínimo Viable (MVP)</span>
                    </h2>

                    <p class="leading-relaxed text-justify  text-white">
                    Junto al equipo de expertos de Ticongle Incubator desarrollarás un prototipo o MVP para validar tu concepto y obtener retroalimentación de usuarios a quienes buscas dirigirte.
                    </p>

                    <div class="flex md:justify-start  justify-center">
                      <a href="#CONTACTANOS"
                        class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                        Más información
                      </a>

                    </div>
                  </div>


                </div>

                <div class="sm:w-1/2">

                  <div class="container">

                    <div class="flex flex-col">

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section class="panel  ">
            <div class="container ">
              <div class="flex flex-wrap px-4">
                <div class="sm:w-1/2 px-4 flex items-center">

                  <div >
                    <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 md:w-[90%] sm:w-full">
                    Investigación de  <span class="text-yellow-incubaodr">Mercado y Validación</span>
                    </h2>

                    <p class="leading-relaxed text-justify text-white">
                    Realizarás una investigación de mercado detallada para confirmar la viabilidad de tu idea. En la cual buscarás obtener comentarios de clientes potenciales para ajustar y mejorar tu producto o servicio.
                    </p>

                    <div class="flex md:justify-start  justify-center">
                      <a href="#CONTACTANOS"
                        class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                        Más información
                      </a>

                    </div>
                  </div>


                </div>

                <div class="sm:w-1/2">

                  <div class="container">

                    <div class="flex flex-col">

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <section class="panel  ">
            <div class="container ">
              <div class="flex flex-wrap px-4">
                <div class="sm:w-1/2 px-4 flex items-center">

                  <div >
                    <h2 class="title-font text-5xl font-medium text-white mt-6 mb-3 md:w-[90%] sm:w-full">
                      Escalabilidad y <span class="text-yellow-incubaodr">Crecimiento</span>
                    </h2>

                    <p class="leading-relaxed text-justify text-white">
                    Desarrollarás estrategias para escalar tu negocio. Verás la capacidad financiera que tendrás para expandir tu equipo, mejorar la infraestructura y buscar nuevas oportunidades de mercado.
                    </p>

                    <div class="flex md:justify-start  justify-center">
                      <a href="#CONTACTANOS"
                        class="mt-8 text-white bg-purpel-incubator border-0 rounded-3xl py-2 px-8 focus:outline-none hover:bg-indigo-600 text-2xl font-semibold">
                        Más información
                      </a>

                    </div>
                  </div>


                </div>

                <div class="sm:w-1/2">

                  <div class="container">

                    <div class="flex flex-col">

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>

                      <div class="p-2 lg:w-1/1 md:w-1/1 w-full">
                        <div class="h-full flex items-center border-gray-200 border-2 p-4 rounded-3xl">
                          <p class="text-white text-justify">
                            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae dicta ratione numquam quaerat
                            temporibus dolore mollitia molestias.
                          </p>
                        </div>
                      </div>



                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        </div>
      </div>

    </>
  );
}
