import React from 'react'
import Navbar2 from '../navbar/navbar2';
import { useNavigate } from 'react-router-dom';

function TerminosCondicionesIncubator() {


  return(
 
    <>
     <Navbar2></Navbar2>
     <div className='bg-[#061357]'>
      <div class="container mx-auto px-4 py-8">

        <h1 class="text-3xl font-semibold mb-6 text-white">Términos y Condiciones</h1>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">1. Presentación </h2>

          <p class="leading-relaxed mb-4 text-white">
            <span className='text-yellow-500' >TICONGLE, S.A. DE C.V.</span> (en adelante, "nosotros" o "la Incubadora") le da la bienvenida (al “usuario”(s) o “usted”) a nuestro sitio web <a href='https://incubator.ticongle.com/' className='text-yellow-500' >https://incubator.ticongle.com/</a> , (en adelante, el “sitio web”), donde encontrará información detallada sobre nuestra empresa y nuestro programa de incubación, conocido como  <span className='text-yellow-500'>"TICONGLE INCUBATOR"</span>.
          </p>

          <p className='leading-relaxed mb-4 text-white'>
            <span className='text-yellow-500'>TICONGLE INCUBATOR</span>, es un programa gestionado por <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span> , que opera como una incubadora de ideas de negocio con base tecnológica. Nuestro objetivo principal es fomentar y facilitar la creación de empresas que aborden las necesidades y problemáticas actuales. Lo logramos a través de una variedad de servicios integrales que incluyen acompañamiento, recursos, capital semilla, asesoría especializada y apoyo estratégico, entre otros, todos ellos destinados a impulsar el desarrollo y el éxito de emprendedores y startups en el ámbito tecnológico, en El Salvador.
          </p>

          <p className='leading-relaxed mb-4 text-white'>
            Con un enfoque proactivo y comprometido, el programa <span className='text-yellow-500'>TICONGLE INCUBATOR</span> aspira a contribuir de manera significativa al crecimiento económico, la generación de empleo y al bienestar social de nuestro país, El Salvador. Estamos dedicados a cultivar un ecosistema emprendedor vibrante y sostenible, donde la innovación y la tecnología se unan para resolver los desafíos del mundo actual.
          </p>

        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">2. Información de la Incubadora </h2>

          <p class="leading-relaxed mb-4 text-white">
            El presente documento contiene las reglas aplicables al uso del sitio web <a href='https://incubator.ticongle.com/' className='text-yellow-500' >https://incubator.ticongle.com/</a>, que es propiedad de <span className='text-yellow-500'>TICONGLE, SOCIEDAD ANÓNIMA DE CAPITAL VARIABLE</span>, que puede abreviarse <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span>, de nacionalidad salvadoreña, del domicilio de San Salvador, de plazo indefinido, con Número de Identificación Tributaria: 0614-201222-105-4, ubicado en Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador.
          </p>

          <p className='leading-relaxed mb-4 text-white'>
            Si necesita comunicarse con nosotros, puede hacerlo a través de nuestro correo electrónico hello@ticongle.agency o por teléfono al +503 7602-8966.
          </p>

        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">3. Aceptación de los términos </h2>

          <p class="leading-relaxed mb-4 text-white">
            Al ingresar, conectarse, acceder o utilizar el sitio web y/o al solicitar la participación en la Incubadora, usted reconoce haber leído y entendido los siguientes términos y condiciones de uso, los cuales incluyen los términos de nuestra Política de Privacidad. Al hacerlo, usted acepta quedar sujeto/a a ellos y cumplir con todas las leyes y regulaciones aplicables relacionadas con el uso del sitio web y la participación en la Incubadora. Reconoce que estos Términos constituyen un contrato legal vinculante y exigible entre <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span> y usted.
          </p>

          <p className='leading-relaxed mb-4 text-white'>
            En caso de que no esté de acuerdo con alguna parte de estos términos, le recomendamos que se abstenga de ingresar, conectarse, acceder o utilizar el sitio web y/o de solicitar y/o participar en la incubadora en cualquier forma.
          </p>

        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">4. Requisito de Edad:  </h2>

          <p class="leading-relaxed mb-4 text-white">
            Para ingresar, conectarse, acceder o utilizar el sitio web y/o solicitar la participación en la Incubadora, debe tener al menos 18 años de edad. Al aceptar estos términos y condiciones, usted declara y garantiza que cumple con este requisito de edad. Los padres, tutores o responsables de los menores de 18 años que utilicen el sitio web son plena y exclusivamente responsables por el uso que este le dé al mismo.
          </p>


        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">5. Términos para participar en la incubadora:  </h2>

          <p class="leading-relaxed mb-4 text-white">
            Los siguientes términos y condiciones se aplicarán a todos los solicitantes y participantes en la Incubadora
          </p>


          <div className='container mx-auto px-4'>

            <h2 class="text-xl font-semibold mb-4 text-white">1. Elegibilidad  </h2>

            <p class="leading-relaxed  text-white">
              Para ser elegible para participar en la Incubadora, debes cumplir con los siguientes requisitos:
            </p>

            <ul className='py-4 text-white'>
              <li>• Ser de nacionalidad salvadoreña.</li>
              <li>• Mayores de 18 años. </li>
              <li>• Contar con una idea basada en el uso de tecnología.</li>
              <li>• Demostrar disposición y voluntad para iniciar un emprendimiento.</li>
            </ul>

            <p className='leading-relaxed mb-4 text-white'>
              Es responsabilidad del solicitante asegurarse de cumplir con cualquier otro criterio de elegibilidad especificado por la Incubadora en el proceso de solicitud.
            </p>

            <p className='leading-relaxed mb-4 text-white'>
              No es necesario ser programador, diseñador o experto en tecnología para participar. Lo fundamental es tener una idea innovadora que tenga el potencial de convertirse en un producto con base tecnológica. Esto puede incluir, entre otros, la creación de videojuegos, aplicaciones móviles, hardware o dispositivos, software, plataformas web, robots, etc.
            </p>

            <p className='leading-relaxed mb-4 text-white'>
              Los directores, representantes, accionistas y empleados de la Incubadora y cualquier persona involucrada en el desarrollo, producción, implementación, evaluación o cumplimiento de la Incubadora (incluyendo, entre otros, sus familiares inmediatos y/o personas que viven en el mismo hogar de tales individuos) no son elegibles para participar en el programa de incubación.
            </p>

          </div>

          <div className='container mx-auto px-4'>

            <h2 class="text-xl font-semibold mb-4 text-white">2.	Proceso de Solicitud</h2>


            <p class="py-2 leading-relaxed  text-white">
              La Incubadora abrirá un espacio en el sitio web donde las personas podrán enviar sus ideas, para participar en el programa de Incubación, el solicitante debe completar y enviar una solicitud de participación según las instrucciones proporcionadas por la Incubadora. Solo se permite una solicitud por persona. La incubadora puede cambiar los formularios de solicitud y exigir que se proporcione información adicional para evaluar la solicitud, en cualquier momento y a nuestro exclusivo criterio.
            </p>

            <p className='py-2 leading-relaxed  text-white'>
              La Incubadora no acepta ninguna responsabilidad por las solicitudes tardías, mal dirigidas, incompletas y que no cumplan con los requisitos especificados. Las aplicaciones incompletas, no elegibles o incomprensibles se considerarán inválidas. Las solicitudes deben presentarse de la manera requerida y recibida por la incubadora durante el período de aceptación de la solicitud que se estipula en el sitio web. No se aceptarán solicitudes tardías.
            </p>

            <p className='py-2 leading-relaxed  text-white'>
              Si proporciona información relacionada con un tercero, como inscribir a un grupo de personas en la Incubadora, usted declara y garantiza que ha obtenido todos los consentimientos necesarios de dichos terceros para proporcionar dicha información a la Incubadora.
            </p>

          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">3. Propiedad Intelectual</h2>
            <p class="py-2 leading-relaxed  text-white">
              Al enviar su solicitud para participar en la Incubadora, el solicitante declara y garantiza que no está violando ningún derecho de propiedad intelectual de terceros. Además, el solicitante reconoce y acepta que cualquier propiedad intelectual desarrollada durante su participación en la Incubadora seguirá siendo de su propiedad, sujeto a cualquier licencia otorgada a la Incubadora según lo acordado por ambas partes.
            </p>
          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">4.	Selección de Ideas</h2>
            <p class="py-2 leading-relaxed  text-white">
              Una vez recibidas todas las solicitudes serán revisadas y evaluadas por el equipo de la Incubadora. Las ideas que cumplan con los criterios de elegibilidad y sean consideradas prometedoras serán seleccionadas para participar en el proceso de evaluación.
            </p>

            <p class="py-2 leading-relaxed  text-white">
              A los solicitantes cuyas ideas sean seleccionadas se les notificará oportunamente a través del correo electrónico o teléfono proporcionado durante el proceso de solicitud.
            </p>
          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">5. Proceso de Evaluación</h2>

            <p class="py-2 leading-relaxed  text-white">
              Las solicitudes seleccionadas serán examinadas y evaluadas por un jurado evaluador (el “Jurado”) compuesto por representantes de la incubadora, y puede incluir la participación de emprendedores, fundadores de startups, socios estratégicos, así como otros expertos o especialistas invitados por la incubadora.
            </p>

            <p class="py-2 leading-relaxed  text-white">
              El jurado evaluará las ideas presentadas utilizando una serie de criterios predefinidos. Estos criterios pueden incluir originalidad, diferenciación, viabilidad técnica, propuesta de valor, necesidad del mercado, ventaja competitiva, escalabilidad, potencial de mercado, crecimiento, experiencia, talento del equipo, entre otros. Cada idea recibirá una puntuación basada en estos criterios. Además, el jurado puede recibir asesoramiento de terceros, como los mentores de la Incubadora (“Mentores”), quienes revisarán y emitirán su voto sobre las mejores ideas. Cualquier tipo de proceso de votación no obligará al jurado y se considerará estrictamente como una recomendación.
            </p>

            <p class="py-2 leading-relaxed  text-white">
              Las ideas que demuestren tener un mayor potencial de éxito y obtengan los puntajes más altos en los criterios de evaluación serán seleccionadas por el jurado para formar parte del Programa de Incubación. Estos participantes serán conocidos como “Finalistas”. Estos finalistas serán los que recibirán un lugar en el Programa de Incubación y tendrán la oportunidad de desarrollar y hacer crecer sus ideas con el apoyo y los recursos proporcionados por la Incubadora.
            </p>

          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">6.	Compromisos del Participante</h2>

            <p class="py-2 leading-relaxed  text-white">
              Al participar en el programa de Incubación, se compromete a dedicar tiempo y esfuerzo suficiente para aprovechar al máximo los recursos y oportunidades proporcionados por la Incubadora.
            </p>

            <p class="py-2 leading-relaxed  text-white">
              Acepta participar activamente en todas las actividades, sesiones de capacitación, mentorías y eventos organizados por la Incubadora, según lo programado y acordado mutuamente.
            </p>

            <p class="py-2 leading-relaxed  text-white">
              Los finalistas también pueden estar obligados a presentar a la incubadora ciertos informes de progreso y otra información y materiales, como parte de su participación en el Programa.
            </p>

          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">7. Duración </h2>
            <p class="py-2 leading-relaxed  text-white">
              La participación en el programa de Incubación estará sujeta a la duración especificada en el Convenio de Incubación que será acordado y firmado entre la Incubadora y cada participante o equipo.
            </p>
          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">8.	Causales de terminación anticipada del programa de incubación </h2>

            <p class="py-2 leading-relaxed  text-white">
              La Incubadora se compromete a proporcionar un entorno de apoyo durante el período establecido en el convenio, fomentando el desarrollo y éxito de los participantes. Sin embargo, la Incubadora se reserva el derecho de dar por terminada la participación de un individuo o equipo en cualquier momento si se constata incumplimiento sustancial de estos Términos, del convenio de incubación, si se determina que el proyecto o la idea no tienen un potencial viable para el éxito comercial, lo que podría ser evidente después de un análisis más profundo durante el proceso de incubación, o si hay cambios significativos en las circunstancias externas, como cambios en el mercado, leyes o la tecnología, que hacen que la continuación del proyecto sea poco práctica o inviable.
            </p>
          </div>

          <div className='container mx-auto px-4 pt-4'>
            <h2 class="text-xl font-semibold mb-4 text-white">9. Asignación de Acciones al Término del Programa de Incubación </h2>

            <p class="py-2 leading-relaxed  text-white">
              Al finalizar el programa de incubación, el participante o equipo, se compromete a formalizar la creación de una sociedad, en la cual las participaciones accionarias serán distribuidas entre el participante o equipo, la Incubadora e inversionistas. El acuerdo de distribución accionaria se formalizará mediante un documento legal que especificará las respectivas participaciones de cada parte.
            </p>

          </div>




        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">6. Restricciones de Uso:</h2>

          <p class="leading-relaxed mb-4 text-white">
            Hay ciertas conductas que están estrictamente prohibidas cuando se usa el sitio. Lea atentamente las siguientes restricciones. El incumplimiento de cualquiera de las disposiciones establecidas en estos términos puede resultar en la terminación de su uso del Sitio y/o Contenido y también puede exponerlo a responsabilidad civil y/o penal. A menos que la Incubadora permita explícitamente lo contrario en virtud de estos Términos o por escrito, no puede:
          </p>

          <div className='container mx-auto px-4'>

            <p class="leading-relaxed  text-white py-2">
              1.	Usar el sitio y/o el Contenido para cualquier propósito ilegal, inmoral y/o no autorizado, incluyendo, entre otros, el acoso a otros usuarios, la violación de derechos de autor u otros derechos de propiedad intelectual, y la transmisión de contenido ofensivo o dañino.
            </p>

            <p class="leading-relaxed  text-white py-2">
              2.	Ingresar información falsa o inexacta a este sitio web.
            </p>

            <p class="leading-relaxed  text-white py-2">
              3.	Usar el Sitio y/o el Contenido para fines no personales o comerciales. El usuario no deberá facilitar acceso a este Sitio web a personas que no califiquen para el uso del mismo ni podrán lucrar, revender o comercializar productos o servicios mediante este Sitio web.
            </p>

            <p class="leading-relaxed  text-white py-2">
              4.	Interferir o violar los derechos de los usuarios a la privacidad y otros derechos, o cosechar o recopilar información de identificación personal sobre los usuarios sin su consentimiento expreso, ya sea manualmente o con el uso de cualquier robot o cualquier aplicación de búsqueda o recuperación, o usar otro dispositivo, proceso o método manual o automático para acceder al Sitio y recuperar, indexar y/o información de minas de datos;
            </p>

            <p class="leading-relaxed  text-white py-2">
              5.	Interferir o interrumpir el funcionamiento del Sitio o los servidores o redes que alojan el Sitio, o desobedece cualquier ley, reglamento, requisito, procedimiento, o políticas de dichos servidores o redes;
            </p>

            <p class="leading-relaxed  text-white py-2">
              6.	Declarar falsamente o tergiversar su afiliación con cualquier persona o entidad, o expresa o implica que la Incubadora lo respalda a usted, a su sitio, su negocio o cualquier declaración que haga, o presente información falsa o inexacta sobre el Sitio;
            </p>

            <p class="leading-relaxed  text-white py-2">
              7.	Tomar cualquier medida que imponga o pueda imponer, una carga irrazonable o desproporcionadamente grande en nuestra infraestructura de plataforma, según lo determinemos nosotros;
            </p>

            <p class="leading-relaxed  text-white py-2">
              8.	Omitir cualquier medida que podamos usar para prevenir o restringir el acceso al Sitio;
            </p>

            <p class="leading-relaxed  text-white py-2">
              9.	Copiar, modificar, alterar, adaptar, poner a disposición, traducir, portar, realizar ingeniería inversa, descompilar o desensamblar cualquier parte del Contenido que la Incubadora pueda acceder en el Sitio o a través de él, o mostrar, reproducir, crear públicamente trabajos derivados de realizar, distribuir o usar dicho Contenido;
            </p>

            <p class="leading-relaxed  text-white py-2">
              10.	Copiar, distribuir, mostrar, ejecutar públicamente, ponga a disposición del público, reduzca a forma legible para humanos, descompile, desensamble, adapte, sublicencia, hacer cualquier uso comercial, vender, alquilar, transferir, prestar, procesar, compilar, realizar ingeniería inversa, combinar con otro software, traducir, modificar o crear trabajos derivados de cualquier material que esté sujeto a los derechos de propiedad de la Incubadora, incluida la Propiedad Intelectual de la Incubadora, de cualquier manera o por cualquier medio;
            </p>

            <p class="leading-relaxed  text-white py-2">
              11.	Hacer uso del Contenido en cualquier otro sitio o entorno informático en red para cualquier propósito sin el consentimiento previo por escrito de la Incubadora;
            </p>

            <p class="leading-relaxed  text-white py-2">
              12.	Vender, licenciar, o explotar para cualquier propósito comercial cualquier uso o acceso al Sitio y/o al Contenido;
            </p>

            <p class="leading-relaxed  text-white py-2">
              13.	Crear una base de datos descargando y almacenando sistemáticamente todo o parte del Contenido del Sitio;
            </p>

            <p class="leading-relaxed  text-white py-2">
              14.	Transmitir o poner a disposición en relación con el Sitio cualquier virus, gusano, caballo de Troya, bomba de tiempo, error web, spyware o cualquier otro código, archivo o programa de computadora que pueda o esté destinado a dañar o secuestrar la operación de cualquier hardware, software, o equipos de telecomunicaciones, o cualquier otro código o componente realmente o potencialmente dañino, disruptivo o invasivo;
            </p>

            <p class="leading-relaxed  text-white py-2">
              15.	Usar el Sitio para cualquier propósito para el cual el Sitio no esté destinado; y/o
            </p>

            <p class="leading-relaxed  text-white py-2">
              16.	Infringir y/o viola cualquiera de los Términos.
            </p>















          </div>


        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">7.	Privacidad y Confidencialidad</h2>

          <p class="leading-relaxed mb-4 text-white">
          Respetamos su privacidad y nos comprometemos a proteger la información que comparte con nosotros. Creemos que tiene derecho a conocer nuestras prácticas con respecto a la información que recopilamos cuando se conecta, accede o usa el Sitio web y/o solicita y participa en la Incubadora. Nuestras políticas, prácticas y el tipo de información recopilada a través del sitio se describen en detalle en nuestra Política de privacidad disponible en https://incubator.ticongle.com/PoliticaPrivacidad que se incorpora aquí por referencia. Usted acepta que la incubadora puede usar la información que usted proporciona o pone a disposición de la incubadora de acuerdo con la Política de Privacidad. Si tiene la intención de acceder o usar el Sitio y/o solicitar la participación en la Incubadora, primero debe leer y aceptar la Política de privacidad.
          </p>
        </div>
        
        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

          <h2 class="text-xl font-semibold mb-4 text-white">8. Propiedad Intelectual</h2>

          <p class="leading-relaxed mb-4 text-white">
            Todo el contenido de este sitio web, incluyendo pero no limitado a marcas, señales de publicidad, texto, gráficos, logotipos, imágenes, videos, software y otros materiales, estén o no registrados o puedan registrarse, pertenece a <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span> o a sus licenciantes y están protegidos por leyes vigentes en El Salvador y los convenios internacionales en materia de propiedad intelectual. Se prohíbe copiar, modificar, distribuir, vender o arrendar alguna de las partes del sitio web. Tampoco pueden modificar, alterar, manipular, ni realizar ingeniería inversa sobre el sitio web propiedad de la incubadora o extraer el código fuente.
          </p>
          
          <p class="leading-relaxed mb-4 text-white">
          Todos los comentarios o sugerencias suministradas por el usuario serán propiedad de la incubadora, quien tendrá una licencia exclusiva, libre de regalías, totalmente pagada, mundial, perpetua e irrevocable para incorporar los comentarios o sugerencias en cualquiera de los servicios y/o actividades actuales o futuros de la incubadora y utilizarlo para cualquier propósito, todo ello sin compensación adicional para usted y sin su aprobación. Usted acepta que todos los comentarios o sugerencias se considerarán no confidenciales. Además, usted garantiza que los comentarios o sugerencias no están sujetos a ningún término de licencia que pretenda exigir a la incubadora el cumplimiento de ninguna obligación adicional con respecto a cualquiera de los productos, tecnologías o servicios actuales o futuros de la incubadora que incorporen cualquier comentario o sugerencia.
          </p>


        </div>
     
        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
          <h2 class="text-xl font-semibold mb-4 text-white">9.	Notificaciones y Comunicaciones</h2>
          <p class="leading-relaxed mb-4 text-white">
          La Incubadora puede enviarle notificaciones, anuncios u otras comunicaciones relacionadas con el sitio web a través de correo electrónico u otros medios. Usted acepta recibir dichas comunicaciones y reconoce que estas notificaciones constituyen un servicio legalmente vinculante.
          </p>
        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
          <h2 class="text-xl font-semibold mb-4 text-white">10. Enlaces a Terceros</h2>
          <p class="leading-relaxed mb-4 text-white">Este sitio web puede contener enlaces a sitios web de terceros que no están bajo el control de <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span> No nos hacemos responsables del contenido, precisión, seguridad o prácticas de privacidad de estos sitios web. La inclusión de cualquier enlace a un sitio web de terceros no implica necesariamente una recomendación o respaldo por parte de <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span> El uso de la información obtenida a través de estos sitios web externos es bajo el propio riesgo del usuario.
          </p>
        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
          <h2 class="text-xl font-semibold mb-4 text-white">11. Limitación de Responsabilidad</h2>
          <p class="leading-relaxed mb-4 text-white">
          El usuario acepta que <span className='text-yellow-500'>TICONGLE, S.A. DE C.V.</span> no está obligado a indemnizar ningún daño o perjuicio del usuario, cualquiera sea su naturaleza (consecuente, indirecto, punitivo, especial o fortuito, previsto o imprevisto) derivados de la utilización o de la imposibilidad de utilizar este Sitio web, de la utilización del usuario de la información y/o resultados obtenidos a través del Sitio web o las decisiones tomadas por el usuario con base en dicha información.
          </p>
        </div>
        
        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
          <h2 class="text-xl font-semibold mb-4 text-white">12. Modificación de Términos y Condiciones de Uso</h2>
          
          <p class="leading-relaxed mb-4 text-white">
          Nos reservamos el derecho de modificar, actualizar o cambiar estos términos y condiciones de uso en cualquier momento y a nuestra sola discreción. Las modificaciones entrarán en vigencia inmediatamente después de su publicación en el sitio web. Se le notificará sobre cualquier cambio significativo mediante un aviso destacado en nuestro sitio web o por otros medios que consideremos adecuados.
          </p>
          
          <p class="leading-relaxed mb-4 text-white">
          Es su responsabilidad revisar periódicamente estos términos y condiciones para estar al tanto de las actualizaciones. Su uso continuado del sitio web después de la publicación de cualquier modificación constituirá su aceptación de dichas modificaciones.
          </p>

          <p class="leading-relaxed mb-4 text-white">
          Si no está de acuerdo con alguna modificación de estos términos y condiciones, le recomendamos que deje de utilizar nuestro sitio web. Sin embargo, si continúa utilizando el sitio web después de la publicación de las modificaciones, se entenderá que ha aceptado los términos y condiciones modificados.
          </p>


        </div>
        
        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
          <h2 class="text-xl font-semibold mb-4 text-white">13. Ley aplicable</h2> 
          <p class="leading-relaxed mb-4 text-white">
          Los presentes Términos y Condiciones se rigen y se interpretan de acuerdo con las leyes vigentes en la República El Salvador. El usuario y la incubadora acuerdan expresamente en someterse a la jurisdicción de los tribunales de la ciudad de San Salvador, República de El Salvador, renunciando a cualquier otro fuero o jurisdicción que pudiera corresponder.
          </p>
        </div>

        <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
          <h2 class="text-xl font-semibold mb-4 text-white">14. Reclamos y Aclaraciones</h2> 
          
          <p class="leading-relaxed mb-2 text-white">
            Para presentar reclamaciones o solicitar aclaraciones, el usuario puede ponerse en contacto con nosotros a través de los siguientes medios:
          </p>

            <ul className='py-4 text-white'>
              <li>● <span className='text-yellow-500'>Correo electrónico: </span> hello@ticongle.agency</li>
              <li>●	<span className='text-yellow-500'>Número de teléfono: </span> +503 7602-8966</li>
              <li>●	<span className='text-yellow-500'>Dirección: </span>Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador.</li>
            </ul>

          <p class="leading-relaxed mb-4 text-white">
            Estamos disponibles para atender sus consultas y resolver cualquier inquietud que pueda surgir en relación con nuestros servicios. Nos comprometemos a responder de manera oportuna y proporcionarle la asistencia necesaria para resolver cualquier problema que pueda surgir.
          </p>

        </div>
      </div>
     </div>

    </>
  );
}

export default TerminosCondicionesIncubator;