import React from 'react'
import Navbar2 from '../navbar/navbar2';

function PolíticaDePrivacidad() {
    return (
        <>
            <Navbar2 />
            <div className='bg-[#061357]'>
                <div class="container mx-auto px-4 py-8">

                    <h1 class="text-3xl font-semibold mb-6 text-white">Política de Privacidad</h1>

                    <p class="leading-relaxed mb-4 text-white">
                        Esta Política de Privacidad describe cómo se recopila, utiliza y protege la información de los usuarios del sitio web del programa de incubación de ideas de negocio con base tecnológica, llamado <span className='text-yellow-500'>TICONGLE INCUBATOR</span>, alojado en www.incubator.ticongle.com (en adelante, "nosotros",  “nuestro” o "la Incubadora")  Al acceder y utilizar nuestra plataforma, aceptas los términos y condiciones de esta Política de Privacidad.
                    </p>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
                        <h2 class="text-xl font-semibold mb-4 text-white">1. Recopilación de información personal </h2>
                        <div className='container mx-auto px-4'>
                            <p class="leading-relaxed mb-4 text-white">
                                1.	Información proporcionada por los usuarios: Al registrarte en nuestro sitio web, podemos recopilar cierta información personal, como tu nombre, dirección de correo electrónico, número de teléfono y otra información relevante para el proceso de registro y evaluación de proyectos.
                            </p>

                            <p class="leading-relaxed mb-4 text-white">
                                2.	Información recopilada durante la evaluación del proyecto: Durante el proceso de evaluación de tu proyecto, es posible que te solicitamos información adicional, como detalles del proyecto, descripción del negocio, análisis de mercado, información financiera y otros documentos relacionados. Esta información puede contener datos confidenciales y comerciales.
                            </p>

                            <p class="leading-relaxed mb-4 text-white">
                                3.	Información recopilada automáticamente: Nuestro sitio web puede recopilar cierta información automáticamente, como tu dirección IP, tipo de navegador, proveedor de servicios de Internet, páginas de referencia/salida, archivos visualizados en nuestra plataforma (por ejemplo, páginas HTML, gráficos, etc.), sistema operativo, fecha/hora de acceso y datos de clics. Utilizamos esta información para analizar tendencias, administrar el sitio  y mejorar la experiencia del usuario.
                            </p>

                            <p class="leading-relaxed mb-4 text-white">
                                4.	Comentarios y comunicaciones: Si nos contactas o interactúas con nosotros a través de la plataforma, podemos recopilar información relacionada con esos comentarios o comunicaciones, incluidos los datos personales que proporciones.
                            </p>

                            <p class="leading-relaxed mb-4 text-white">
                                4.	Comentarios y comunicaciones: Si nos contactas o interactúas con nosotros a través de la plataforma, podemos recopilar información relacionada con esos comentarios o comunicaciones, incluidos los datos personales que proporciones.
                            </p>

                        </div>
                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

                        <h2 class="text-xl font-semibold mb-4 text-white">2. Uso de la información recopilada </h2>

                        <div className='container mx-auto px-4'>

                            <p class="leading-relaxed mb-4 text-white">
                                1.	Utilización de la información personal: Utilizaremos la información personal recopilada para evaluar tu proyecto, proporcionarte servicios de incubación empresarial y brindarte apoyo durante el proceso. También podemos utilizar tu información para comunicarnos contigo, proporcionarte actualizaciones sobre nuestra plataforma y ofrecerte oportunidades relevantes para el crecimiento de tu negocio.
                            </p>

                            <p class="leading-relaxed mb-4 text-white">
                                2.	Compartir información con terceros: Podemos compartir tu información personal con nuestro equipo de evaluación y asesores comerciales, así como con posibles inversores y socios comerciales interesados en tu proyecto. Sin embargo, nos comprometemos a mantener la confidencialidad de tu información y solo compartiremos lo necesario para los fines específicos mencionados.
                            </p>

                            <p class="leading-relaxed mb-4 text-white">
                                3.	Cumplimiento legal: Podemos divulgar tu información personal si así lo exige la ley o si creemos de buena fe que dicha divulgación es necesaria para proteger nuestros derechos, cumplir con un proceso legal, responder a una solicitud gubernamental o proteger la seguridad de otros usuarios.
                            </p>


                        </div>


                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

                        <h2 class="text-xl font-semibold mb-4 text-white">3. Seguridad de la información </h2>

                        <div className='container mx-auto px-4'>

                            <p class="leading-relaxed mb-4 text-white">
                                Implementamos medidas de seguridad razonables para proteger tu información personal contra el acceso no autorizado, la divulgación, la alteración o la destrucción. Mantenemos la confidencialidad de tu proyecto y limitamos el acceso a tu información solo a las personas que necesitan conocerla para evaluar y brindar servicios relacionados con tu proyecto
                            </p>


                        </div>


                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">

                        <h2 class="text-xl font-semibold mb-4 text-white">4. Derechos del titular de los datos</h2>

                        <div className='container mx-auto px-4'>

                            <p class="leading-relaxed mb-4 text-white">
                                Te otorgamos ciertos derechos sobre tus datos personales, incluido el derecho de acceso, rectificación, eliminación, restricción del procesamiento y portabilidad de datos. Puedes ejercer estos derechos y ponerte en contacto con nosotros para cualquier consulta o solicitud relacionada con tus datos personales a través de hello@ticongle.agency.
                            </p>


                        </div>


                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
                        <h2 class="text-xl font-semibold mb-4 text-white">5. Retención de datos</h2>
                        <div className='container mx-auto px-4'>
                            <p class="leading-relaxed mb-4 text-white">
                                Mantendremos tu información personal durante el tiempo necesario para cumplir con los propósitos mencionados en esta Política de Privacidad, a menos que se requiera o permita un período de retención más prolongado por ley.
                            </p>
                        </div>
                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
                        <h2 class="text-xl font-semibold mb-4 text-white">6. Enlaces a sitios web de terceros</h2>
                        <div className='container mx-auto px-4'>
                            <p class="leading-relaxed mb-4 text-white">
                                Nuestro sitio web puede contener enlaces a otros sitios web de terceros. Esta Política de Privacidad solo se aplica a nuestra plataforma, por lo que te recomendamos que revises las políticas de privacidad de los sitios web de terceros que visites.
                            </p>
                        </div>
                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
                        <h2 class="text-xl font-semibold mb-4 text-white">7. Cambios en la Política de Privacidad</h2>
                        <div className='container mx-auto px-4'>
                            <p class="leading-relaxed mb-4 text-white">
                                Nos reservamos el derecho de modificar o actualizar esta Política de Privacidad en cualquier momento. Te recomendamos que revises periódicamente esta página para estar al tanto de los cambios. La fecha de entrada en vigencia de la Política de Privacidad actualizada se indicará al comienzo del documento.
                            </p>
                        </div>
                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
                        <h2 class="text-xl font-semibold mb-4 text-white">7. Cambios en la Política de Privacidad</h2>
                        <div className='container mx-auto px-4'>
                            <p class="leading-relaxed mb-4 text-white">
                                Nos reservamos el derecho de modificar o actualizar esta Política de Privacidad en cualquier momento. Te recomendamos que revises periódicamente esta página para estar al tanto de los cambios. La fecha de entrada en vigencia de la Política de Privacidad actualizada se indicará al comienzo del documento.
                            </p>
                        </div>
                    </div>

                    <div class="shadow-md rounded-lg p-8 my-4 bg-[#050C32]">
                        <h2 class="text-xl font-semibold mb-4 text-white">8.	Contacto</h2>

                        <p class="leading-relaxed mb-2 text-white">
                            Si tiene alguna pregunta, inquietud o solicitud con respecto a esta Política de Privacidad, no dudes en ponerte en contacto con nosotros a través de los siguientes medios:
                        </p>

                        <ul className='py-4 text-white'>
                            <li>● <span className='text-yellow-500'>Correo electrónico:</span> hello@ticongle.agency</li>
                            <li>● <span className='text-yellow-500'>Número de teléfono:</span> +503 7602-8966</li>
                            <li>● <span className='text-yellow-500'>Dirección:</span> Calle Reforma, Local 251, Colonia San Benito, en la ciudad de San Salvador, República de El Salvador.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PolíticaDePrivacidad;