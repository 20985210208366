
import { BrowserRouter, Route, Routes, Link, Navigate } from 'react-router-dom'

// Views
import Home from '../home/home';

import TyCIncubator from "../TyCIncubator/TermiYCondi";
import Navbar from '../navbar/navbar';
import PolíticaDePrivacidad from '../TyCIncubator/PolíticaDePrivacidad';
import Header from '../header/header';

const RouterApp = () => {
   return(
      <>
       
       <Navbar />
       <Header/>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/Terminos-Y-Condiciones' element={<TyCIncubator />} /> 
            <Route path='/Politica-de-Privacidad' element={<PolíticaDePrivacidad />} /> 
        </Routes>

      </>
   );

};

export default RouterApp;
