import React from 'react'

import coheteAnimate from "../assets/cohete/completo.svg";

import SvgComponentCohete from './animate-cohete/cohete';
import SvgComponentHumo from './animate-cohete/complementCohete';

function Header() {

    return (
        <div class="container-fluid body-fon bg-bgImageContactUs bg-cover bg-center bg-no-repeat sm:pt-12 xl:pt-0" id="INICIO">

            <div class="mx-auto hidden lg:block bg-black bg-opacity-50 ">
                {/* <div class="flex flex-wrap w-full relative h-[745px]"> */}
                <div class="w-full relative responsive-py-header">


                    <div class="relative z-10 container">

                        <div class="flex flex-wrap pt-16 ">
                            <div class="md:w-2/2 w-full">
                                <div class="h-full flex justify-center flex-col px-3 md:px-5 md:py-0 py-3">
                                    <h1 className="sm:text-6xl xs:text-3xl text-white lg:leading-normal leading-normal font-medium mb-7 position-relative w-2/4">
                                        Centro de Innovación en Activos Digitales <span className="text-white0-500 text-lg mt-4 lg:text-2xl">(CIAD)</span>
                                    </h1>

                                    <div class="">
                                        <div class="">
                                            <p className="pt-4 text-white opacity-80 mb-0 w-4/5 text-lg xl:text-xl text-justify font-semibold">
                                                El Centro de Innovación en Activos Digitales (CIAD) es una iniciativa pionera ubicada estratégicamente en San Salvador (El Salvador), diseñada para convertirse en un catalizador de la innovación, la educación y el desarrollo dentro del ecosistema de Bitcoin y activos digitales.
                                            </p>
                                            <p className="pt-4 text-white opacity-80 mb-0 w-4/5 text-lg xl:text-xl text-justify font-semibold">
                                                Su misión es fomentar la adopción responsable y regulada de tecnologías emergentes, posicionando a El Salvador como un líder global en la criptoeconomía.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="block bg-black bg-opacity-50 lg:hidden">
                <section class="text-gray-600 body-font bg-bgImageContactUs bg-cover bg-center bg-no-repeat   pt-16">

                    <div class=" pt-11 mx-auto ">

                        <div class="flex flex-wrap">


                            <div class="p-4 lg:w-1/2 w-1/1 px-4">
                                <div class="text-left">
                                    <div class="w-full h-full ">

                                        <   h1 className="text-2xl text-white lg:leading-normal leading-normal font-medium mb-7 position-relative w-1/1">
                                            Centro de Innovación en Activos Digitales <span className="text-white0-500 text-lg mt-4 lg:text-2xl">(CIAD)</span>
                                        </h1>

                                        <p class="p-2 text-white text-lg text-justify">
                                            El Centro de Innovación en Activos Digitales (CIAD) es una iniciativa pionera ubicada estratégicamente en San Salvador (El Salvador), diseñada para convertirse en un catalizador de la innovación, la educación y el desarrollo dentro del ecosistema de Bitcoin y activos digitales.
                                        </p>

                                        <p class="p-2 text-white text-lg text-justify">
                                            Su misión es fomentar la adopción responsable y regulada de tecnologías emergentes, posicionando a El Salvador como un líder global en la criptoeconomía.


                                        </p>



                                    </div>


                                </div>
                            </div>





                        </div>
                    </div>
                </section>
            </div>

        </div>

    )
}

export default Header