
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import App from "./app";

const rootElement = document.getElementById("root");
AOS.init();

ReactDOM.render(
  <StrictMode>
  <App></App>
  </StrictMode>,
  rootElement
);
