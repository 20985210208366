

import startups from "../assets/images/startup.png";

import publiclibrary from "../assets/images/publiclibrary.png";
import agreement from "../assets/images/agreement.png";
import regulation from "../assets/images/regulation.png";


import training from "../assets/images/training.png";
import research from "../assets/images/research.png";
import partnership from "../assets/images/partnership.png";

import compliant from "../assets/images/compliant.png";
import logicalthinking from "../assets/images/logicalthinking.png";
import Collaborative from "../assets/images/Collaborative.png";


export default function Ciad() {

    return (
        <>
            <div className="container mx-auto py-16" data-aos="fade-up"
                data-aos-anchor-placement="top-bottom">
                <div className="-my-8 divide-y-2 divide-gray-100">
                    <div className="py-8 flex flex-wrap md:flex-nowrap">

                        <div className="md:flex-grow">
                            <h1 className="text-4xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full">Ecosistema de Activos Digitales</h1>
                            <p className="leading-relaxed">Nuestros servicios están diseñados para una amplia gama de clientes interesados en el desarrollo y aplicación de activos digitales. Desde emprendedores y startups que buscan crear proyectos innovadores, hasta estudiantes y profesionales deseosos de adquirir conocimientos en Bitcoin y blockchain. También atendemos a instituciones financieras que buscan modernizar sus servicios, organismos internacionales y ONG que promueven la inclusión financiera, así como gobiernos y reguladores interesados en regular y fomentar el uso de la tecnología en sus gestiones administrativas.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto py-16"  >

                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                    <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                        <img src={startups} />
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-2xl text-[#1A2E6E] lg:leading-normal leading-normal font-medium position-relative w-full" >Emprendedores y Startups:</h2>
                        <p className="leading-relaxed text-base">Personas y equipos que buscan desarrollar proyectos en el ámbito de los activos digitales, bitcoin y la criptoeconomía.</p>

                    </div>
                </div>

                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-2xl text-[#1A2E6E] lg:leading-normal leading-normal font-medium position-relative w-full">Instituciones Financieras</h2>
                        <p className="leading-relaxed text-base">Bancos, fondos de inversión y empresas fintech que buscan innovación en sus servicios.</p>

                    </div>
                    <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">

                        <img src={publiclibrary} />
                    </div>
                </div>

                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                    <div className="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                        <img src={agreement} />
                    </div>
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-2xl text-[#1A2E6E] lg:leading-normal leading-normal font-medium position-relative w-full">
                            Organismos Internacionales y ONG</h2>
                        <p className="leading-relaxed text-base">Organizaciones que desean colaborar en proyectos de inclusión financiera y desarrollo tecnológico.</p>

                    </div>
                </div>

                <div className="flex items-center lg:w-4/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                    <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                        <h2 className="text-2xl text-[#1A2E6E] lg:leading-normal leading-normal font-medium position-relative w-full">Gobierno y Reguladores:</h2>
                        <p className="leading-relaxed text-base">Entidades gubernamentales interesadas en regular y fomentar el uso de activos digitales y su aplicación en todas las áreas de gestión gubernamental: Registros Públicos, Compras públicas, Tributos, Órgano Judicial, y otros</p>

                    </div>
                    <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                        <img src={regulation} />
                    </div>
                </div>


            </div>

            <div className="container mx-auto mb-20"  >

                <div className="container px-5 mx-auto" data-aos="fade-up"
                    data-aos-anchor-placement="top-bottom">
                    <div className="-my-8 divide-y-2 divide-gray-100">
                        <div className="py-8 flex flex-wrap md:flex-nowrap">
                            <div className="md:flex-grow">
                                <h2 className="text-4xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full">Segmentos </h2>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 ">

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">
                        <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                            <img src={training} />
                        </div>
                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Educación y Capacitación
                            </a>
                            <p className="text-black mt-3 text-justify">Programas especializados en Bitcoin y activos digitales para estudiantes, profesionales y emprendedores, impartidos por expertos locales e internacionales.</p>

                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">
                        <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                            <img src={research} />
                        </div>
                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Innovación y Desarrollo
                            </a>
                            <p className="text-black mt-3 text-justify">Un hub de incubación para startups fintech, proporcionando recursos, mentoría y acceso a una red global de expertos.</p>

                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">
                        <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                            <img src={compliant} />
                        </div>
                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Conexiones Internacionales
                            </a>
                            <p className="text-black mt-3 text-justify">Alianzas estratégicas con instituciones globales para compartir conocimiento, recursos y mejores prácticas.</p>

                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">
                        <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                            <img src={partnership} />
                        </div>
                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Asesoría en Compliance y Regulación
                            </a>
                            <p className="text-black mt-3 text-justify">Servicios de asesoría para el cumplimiento normativo de proyectos de activos digitales, tanto a nivel local como internacional.</p>

                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">
                        <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                            <img src={logicalthinking} />
                        </div>
                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Investigación y Publicaciones
                            </a>
                            <p className="text-black mt-3 text-justify">Desarrollo de estudios y publicaciones en el ámbito de activos digitales y su impacto en la economía.</p>

                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up" id="Educacion">
                        <div className="w-20 h-20 bg-orange-600/5 text-orange-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm">
                            <img src={Collaborative} />
                        </div>
                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Ecosistema Colaborativo
                            </a>
                            <p className="text-black mt-3 text-justify">Un espacio que promueve la colaboración entre empresas, startups, instituciones académicas y gubernamentales.</p>

                        </div>
                    </div>



                </div>
            </div>

            <div className="container px-5 mx-auto">

                <div className="flex flex-wrap w-full flex-col items-center text-center">


                    <div className="lg:flex-grow lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-[#1A2E6E]">
                            Introducción a la Actividad en Educación y Formación del CIAD
                        </h1>
                        <p className="mb-8 leading-relaxed text-justify">
                            El Centro de Innovación en Activos Digitales (CIAD) de la Comisión Nacional de Activos Digitales (CNAD), en El Salvador, ha desarrollado una propuesta educativa integral centrada en formar profesionales y ciudadanos en el uso, gestión y creación de soluciones tecnológicas dentro del ecosistema de activos digitales. En un contexto donde Bitcoin es moneda de curso legal, el CIAD se posiciona como un referente clave en la capacitación técnica y práctica para la adopción de tecnologías descentralizadas, fomentando la innovación y el desarrollo económico basado en activos digitales y software de código abierto.
                           
                        </p>

                        <h1 className="title-font sm:text-2xl text-3xl mb-4 font-medium text-[#1A2E6E]">
                            Cursos de Formación Continua: Niveles Básico, Intermedio y Avanzado
                        </h1>
                        <p className="mb-8 leading-relaxed text-justify">
                            El CIAD también ofrece un programa de formación continua compuesto por cursos de niveles básico, intermedio y avanzado, enfocados en la formación de personas interesadas en adquirir o mejorar sus competencias en activos digitales. Estos cursos son ideales tanto para principiantes como para profesionales que deseen especializarse en áreas como Bitcoin, Ethereum, Lightning Network y la tokenización de activos.
                        </p>

                    </div>

                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">

                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Curso Básico en Bitcoin y Activos Digitales

                            </a>
                            <p className="text-black mt-3 text-justify">
                                Este curso de 4 semanas está diseñado para ofrecer una introducción sólida a conceptos fundamentales como la historia del dinero, los principios de Bitcoin, y el uso de billeteras digitales. El enfoque está en que los estudiantes comprendan cómo funcionan las criptomonedas y cómo protegerse en este entorno digital.
                            </p>
                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">

                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Curso Intermedio en Bitcoin y Activos Digitales


                            </a>
                            <p className="text-black mt-3 text-justify">
                                El curso intermedio tiene una duración de 6 semanas y profundiza en aspectos clave como la gestión de activos digitales, ciberseguridad, y cumplimiento normativo. Los participantes también aprenderán a desarrollar soluciones basadas en blockchain, incluyendo la configuración de servidores y la integración de bases de datos.
                            </p>
                        </div>
                    </div>

                    <div className="features p-8 shadow-lg shadow-slate-100 dark:shadow-slate-800 transition duration-500 rounded-3xl mt-16" data-aos="zoom-out-up">

                        <div className="content mt-7">
                            <a className="text-2xl  text-[#1A2E6E] lg:leading-normal leading-normal font-medium mb-7 position-relative w-full" href="/index">
                                Curso Avanzado en Bitcoin y Activos Digitales

                            </a>
                            <p className="text-black mt-3 text-justify">
                                Este programa de 8 meses está orientado a profesionales que buscan liderar proyectos en el ecosistema blockchain. Los estudiantes aprenderán sobre la programación avanzada de contratos inteligentes, auditoría de seguridad en sistemas descentralizados, y regulaciones internacionales aplicadas a los activos digitales.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )

};